<template>
  <b-card class="user-delivery">
    <b-row class="show-tabs-list-mobile">
      <b-col cols="12">
        <b-tabs
          pills
          class="nav-tabs-center"
        >
          <b-tab active>
            <template #title>
              <span class="d-none d-sm-inline">{{ $t('My Deliveries') }}</span>
            </template>
            <good-data-table
              :columns="myDeliveryColumns"
              :total-column="'response.responseData.delivery.total'"
              :data-column="'response.responseData.delivery.data'"
              :api-endpoint="'/user/trips-history/delivery'"
              :default-sort-params="{
                field: 'created_time',
                type: 'desc',
              }"
            />
          </b-tab>
          <b-tab>
            <template #title>
              <span class="d-none d-sm-inline">{{ $t('Upcoming Deliveries') }}</span>
            </template>
            <good-data-table
              :columns="upcomingDeliveryColumn"
              :total-column="'response.responseData.delivery.total'"
              :data-column="'response.responseData.delivery.data'"
              :api-endpoint="'/user/trips-history/delivery'"
              :extra-params="'type=upcoming'"
              :default-sort-params="{
                field: 'created_time',
                type: 'desc',
              }"
            />
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BRow, BCol, BTab, BTabs, BCard } from 'bootstrap-vue'
import GoodDataTable from '@/@core/layouts/shared/GoodDataTable.vue'

export default {
  components: {
    BRow,
    BCol,
    GoodDataTable,
    BTab,
    BTabs,
    BCard,
  },
  data() {
    return {
      myDeliveryColumns: [
        {
          label: 'Booking Id',
          field: 'booking_id',
        },
        {
          label: 'Date',
          field: 'created_time',
        },
        {
          label: 'Pickup Location',
          field: 's_address',
        },
        {
          label: 'Drop Location',
          field: 'd_address_locations',
          useResolver: true,
          useRenderer: true,
          renderer: props => {
            if (props.deliveries) {
              return props.deliveries.map(x => x.d_address).join(', ')
            }
            return ''
          },
        },
        {
          label: 'Total Amount',
          field: 'provider_total_amount',
          useResolver: true,
          useRenderer: true,
          renderer: props => {
            if (props.deliveries) {
              return `${props.user.currency_symbol} ${props.deliveries
                .filter(x => x.payment)
                .map(x => x.payment.total)
                .reduce((a, b) => a + b, 0)}`
            }
            return 'NA'
          },
        },
        {
          label: 'Payment',
          field: 'payment_wallet',
          useResolver: true,
          useRenderer: true,
          renderer: props => {
            if (props.payment) {
              // eslint-disable-next-line eqeqeq
              if (props.payment.is_partial != 1 && props.payment.wallet > 0) {
                return 'WALLET'
              }
              return props.payment.payment_mode != null ? props.payment.payment_mode : 'NA'
            }
            return 'NA'
          },
        },
        {
          label: 'Status',
          field: 'status',
          align: 'center',
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
          type: 'dropdown',
          actions: [
            {
              isIcon: true,
              iconHtml: this.$helpers.getIcons('view'),
              text: 'View',
              action: props => {
                this.viewMyDeliverysDetails(props)
              },
            },
            {
              isIcon: true,
              iconHtml: this.$helpers.getIcons('dispute'),
              text: 'Dispute',
              action: props => {
                this.createMyDeliverysDispute(props)
              },
            },
          ],
        },
      ],
      upcomingDeliveryColumn: [
        {
          label: 'Booking Id',
          field: 'booking_id',
        },
        {
          label: 'Date',
          field: 'schedule_time',
        },
        {
          label: 'Total Amount',
          field: 'provider_id',
          useRenderer: true,
          renderer: props => {
            if (props.payment) {
              return `${props.user.currency_symbol} ${props.payment.total}`
            }
            return '0.00'
          },
        },
        {
          label: 'Payment',
          field: 'ride_delivery_id',
          useRenderer: true,
          renderer: props => {
            if (props.payment_mode) {
              return props.payment_mode
            }
            return 'NA'
          },
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
          type: 'dropdown',
          actions: [
            {
              isIcon: true,
              iconHtml: this.$helpers.getIcons('view'),
              text: 'View',
              action: props => {
                this.viewUpcomingDeliverysDetails(props)
              },
            },
          ],
        },
      ],
    }
  },
  methods: {
    viewMyDeliverysDetails(props) {
      console.log(props)
    },
    createMyDeliverysDispute(props) {
      console.log(props)
    },
    viewUpcomingDeliverysDetails(props) {
      console.log(props)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
